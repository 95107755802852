





























































































import Vue from 'vue'
import {
  Word,
} from '@/types/word'
import {
  PropValidator,
} from 'vue/types/options'
import {
  newWord,
} from '@/util/word'
import {
  DataTableHeader,
} from 'vuetify'
import cloneDeep from 'clone-deep'

export default Vue.extend({
  props: {
    word: {
      default: newWord(),
      type: Object,
    } as PropValidator<Word>,
  },
  data: () => ({
    headers: [
      {
        value: `translations`,
        text: `Translations`,
        sortable: false,
      },
    ] as DataTableHeader[],
    word_: newWord(),
  }),
  mounted () {
    this.word_ = cloneDeep(this.word)
  },
  methods: {
    async moveTranslationUp (index: number) {
      const {
        translations,
      } = this.word_
      const translation = translations.splice(index, 1)[0]
      translations.splice(index - 1, 0, translation)
    },
    async moveTranslationDown (index: number) {
      const {
        translations,
      } = this.word_
      const translation = translations.splice(index, 1)[0]
      translations.splice(index + 1, 0, translation)
    },
  },
})
